import request from '@app/js/api/request';
import _debounce from 'lodash/debounce';



export const measurePerformance = (actionName, actionFunction) => {
	startPerformance(actionName);

	// Execute the action function
	actionFunction();

	return stopPerformance(actionName);
};

export const startPerformance = (actionName) => {
	clearPerformance(actionName);
	if (window.performance && window.performance.mark) {
		const markStart = `${actionName}-start`;

		// Mark the start time
		window.performance.mark(markStart);
	}
};

export const stopPerformance = (actionName, tags) => {
	if (window.performance && window.performance.mark) {
		const markStart = `${actionName}-start`;
		const markEnd = `${actionName}-end`;

		const startEntries = window.performance.getEntriesByName(markStart, 'mark');
		if (startEntries.length > 0) {
			// Mark the end time
			window.performance.mark(markEnd);

			// Measure the duration
			window.performance.measure(actionName, markStart, markEnd);

			// Get the measurement result
			const measurement = window.performance.getEntriesByName(actionName)[0];

			const duration = measurement.duration;
			clearPerformance(actionName);

			recordTiming(actionName, duration, tags);

			return duration;
		}
	}
	return null;
};

export const clearPerformance = (actionName) => {
	if (window.performance && window.performance.clearMarks) {
		// Clear the marks and measurement
		const markStart = `${actionName}-start`;
		const markEnd = `${actionName}-end`;
		window.performance.clearMarks(markStart);
		window.performance.clearMarks(markEnd);
		window.performance.clearMeasures(actionName);
	}
};


export const enqueueMetric = (() => {
	let metricQueue = [];
	const sendMetricQueue = async () => {
		await request.post('/app/record-metrics', { metrics: metricQueue.splice(0, metricQueue.length) }); // splicing like this returns all array elements, while simultaneously emptying out the array
		metricQueue = [];
	};

	const ONE_SECOND = 1000;
	const recordMetric = _debounce(sendMetricQueue, 10 * ONE_SECOND, { maxWait: 30 * ONE_SECOND });

	return function _enqueueMetric(method, name, value, tags) {
		metricQueue.push({
			method,
			name,
			value: Math.round(value),
			tags,
		});
		recordMetric();
	};
})();

export const recordTiming = async (actionName, value, tags) => {
	if (value !== null) {
		enqueueMetric('timing', `performance.client.${actionName}`, value, tags);
	}
};


