import { types, flow, getEnv } from 'mobx-state-tree';
import request from '@app/js/api/request';
import { message } from 'antd';
import { t } from 'i18next';
import _get from 'lodash/get';
import _debounce from 'lodash/debounce';
import { userHasPermission, userHasAppPermission } from '@utils/authorization';
import { filteredRolesByProgram } from '@utils/filters';
import Filters from '@baseModels/filters.baseModel';
import i18next from 'i18next';
import { addDays, isBefore } from '@utils/dateTimeService';

const { string, maybe, maybeNull, model, optional, array, boolean, map, frozen, integer, union } = types;

const FeatureFlagScopeModel = map(array(string));

const FeatureFlagModel = model('FeatureFlagModel', {
	consultancy: FeatureFlagScopeModel,
	organization: FeatureFlagScopeModel,
	program: FeatureFlagScopeModel,
	user: FeatureFlagScopeModel,
});

export const FeatureModel = model('FeatureModel', {
	feature_id: string,
	feature_image_url: string,
	title: string,
	description: string,
	action_button: model('ActionButtonModel', {
		text: optional(string, ''),
		url: optional(string, ''),
	}),
	isViewed: optional(boolean, false),
}).actions((self) => ({
	setIsViewed(isViewed) {
		self.isViewed = isViewed;
	},
}));

// Model
export const UserStoreModel = model('UserStore', {
	user: maybeNull(model({
		attributes: model({
			time_zone: maybe(string),
			department: maybe(string),
			level: maybe(string),
			has_set_notifications: maybe(boolean),
			has_beta_access: maybe(boolean),
		}),
		consultancy_id: maybeNull(string),
		created_at: optional(string, ''),
		deleted_at: maybeNull(string),
		email: optional(string, ''),
		featureFlags: optional(FeatureFlagModel, {}),
		featurePreviews: array(FeatureModel),
		first_name: maybeNull(string),
		hasNotifications: optional(boolean, false),
		last_name: maybeNull(string),
		full_name: optional(string, ''),
		initials: optional(string, ''),
		last_login: maybeNull(string),
		num_logins: optional(integer, 0),
		organization_id: maybeNull(string),
		organization: maybeNull(model({
			consultancy_id: optional(string, ''),
		})),
		permissions: map(map(map(map(map(map(map(union(boolean, frozen())))))))),
		role: array(model({
			name: optional(string, ''),
		})),
		user_roles: array(frozen()),
		selected_organization: maybeNull(string),
		status: optional(string, ''),
		updated_at: optional(string, ''),
		user_id: optional(string, ''),
		user_name: optional(string, ''),
		notifications: array(model('notification', {
			event: optional(string, ''),
			entityId: optional(string, ''),
			entityType: optional(string, ''),
			filter: maybe(model({
				name: optional(string, ''),
				filter_id: optional(string, ''),
			})),
		})),
		roleFilters: array(model('roleFiltersModel', {
			configuration: optional(model('configurationModel', {
				filters: optional(Filters, {
					logicalOperator: 'and',
					expressions: [],
				}),
			}), {}),
			resourceId: optional(string, ''),
		})),
		savedFilters: array(model('savedFiltersModel', {
			filter_id: optional(string, ''),
			program_id: optional(string, ''),
			configuration: optional(model('savedFilterModel', {
				name: optional(string, ''),
			}), {}),
		})),
		isIFrame: optional(boolean, false),
		password_updated_at: maybeNull(string),
	})),
	isSSO: optional(boolean, false),
	errorCode: optional(string, ''),
	csrf_token: optional(string, ''), // This token can included as a header on post/put/delete requests to endpoints with CSRF protection.
	idle_session_expiration: maybe(integer),
	abs_session_expiration: maybe(integer),
	_subscribedTags: optional(array(string), []),
})
	.views((self) => ({
		get organizationStore() {
			return getEnv(self).OrganizationStore || {};
		},
		get program() {
			return getEnv(self).ProgramStore || {};
		},
		get isConsultant() {
			// user will not be defined with a share deal link
			return !!self.user?.consultancy_id && !self.user?.organization_id;
		},
		// This needs to be a function and not a getter so it doesn't cache the result
		isOrganizationAdmin() {
			return self.checkUserOrganizationAccess('modify', 'user', '*', '*', '*')
				|| self.checkUserOrganizationAccess('view', 'usage', '*', '*', '*');
		},
		// This needs to be a function and not a getter so it doesn't cache the result
		canViewOrganizationRoute() {
			return self.checkUserOrganizationAccess('modify', 'user', '*', '*', '*')
				|| self.checkUserOrganizationAccess('view', 'usage', '*', '*', '*')
				|| self.checkUserOrganizationAccess('view', 'program', null, '*', '*');
		},
		// This needs to be a function and not a getter so it doesn't cache the result
		canViewConsultancyRoute() {
			return self.checkUserConsultancyAccess('view', 'organization', null)
				|| self.checkUserConsultancyAccess('view', 'user', '*');
		},
		// This needs to be a function and not a getter so it doesn't cache the result
		canViewContributorRoute() {
			return self.checkUserOrganizationAccess('modify', 'program', null, 'workflow', null)
				|| self.checkUserOrganizationAccess('modify', 'program', null, 'workflowApproval', null);
		},
		hasWorkflowApprovalRole(programId = null, workflowId = null) {
			return self.checkUserOrganizationAccess('modify', 'program', programId || null, 'workflowApproval', workflowId || null);
		},
		hasAppPermission(programId, permission) {
			return userHasAppPermission(programId, permission);
		},
		hasFeatureFlag(flag, _scopesToCheck = ['consultancy', 'organization', 'program', 'user']) {
			const scopesToCheck = new Set(_scopesToCheck);
			scopesToCheck.add('consultancy');
			const consultancyId = self.user?.consultancy_id || self.user?.organization?.consultancy_id;
			const { organizationId } = self.organizationStore;
			const { programId } = self.program;
			const scopes = [{
				scope: 'consultancy',
				scopeId: consultancyId,
			}, {
				scope: 'organization',
				scopeId: organizationId,
			}, {
				scope: 'program',
				scopeId: programId,
			}, {
				scope: 'user',
				scopeId: self.user?.user_id,
			}];
			return scopes.reduce((hasFlag, { scope, scopeId }) => {
				if (!scopesToCheck.has(scope))  {
					return hasFlag;
				}
				const scopeIdFlags = self.user?.featureFlags?.[scope].get(scopeId);
				if (!!scopeIdFlags) {
					return hasFlag || scopeIdFlags.includes(flag);
				}
				return hasFlag;
			}, false);
		},
		get hasFilteredRoleApplied() {
			return filteredRolesByProgram(self.user.user_roles).length > 0;
		},
		get hasDepartment() {
			return !!self.user.attributes?.department;
		},
		get initialDepartment() {
			return self.hasDepartment ? self.user.attributes.department : null;
		},
		get hasLevel() {
			return !!self.user.attributes?.level;
		},
		get initialLevel() {
			return self.hasLevel ? self.user.attributes.level : null;
		},
		get hasSetNotifications() {
			// User has already selected notifications or 'has_set_notifications' is true
			return self.user?.notifications?.length || !!self.user?.attributes?.has_set_notifications;
		},
		get isIFrame() {
			return self?.user?.isIFrame ?? false;
		},
		get isFirstTimeLoggingIn() {
			return self.user.num_logins === 1;
		},
		get isPendingUser() {
			return self.user.status === 'pending';
		},
		getParam(param) {
			const query = new URLSearchParams(window.location?.search);
			return query.get(param) || null;
		},
		get responseUrlSearchParams() {
			let params = '';
			const action = self.getParam('action');
			switch (action) {
				case 'scrollToThreadReaction':
				case 'scrollToTagReaction':
				case 'favoriteDeal':
				case 'showFavoriters':
					params = `?action=${action}`;
					break;
				case 'showNewThread':
				case 'showNewTag':
					params = `?highlightToSave=${self.getParam('highlightToSave')}&highlightLocation=${self.getParam('highlightLocation')}&action=${action}`;
					break;
				case 'deleteTag':
					params = `?tagId=${self.getParam('tagId')}&highlightLocation=${self.getParam('highlightLocation')}&action=${action}`;
					break;
				default:
					params = '';
					break;
			}
			if (self.getParam('threadId')) {
				params = `?threadId=${self.getParam('threadId')}`;
			}
			return params;
		},
		// Function to check the user and/or the org has a product feature.
		userHasOrgFeature(name) {
			if (self.isConsultant) {
				return true; // Consultant level users have access to all features.
			}

			return self.organizationStore.orgHasFeature(name) || false;
		},
		get interviewTypeOptions() {
			const interviewPackage = self.organizationStore.selectedOrganization.interview_package;
			const softwarePackage = self.organizationStore.selectedOrganization.software_package;
			const orgFeatures = self.organizationStore.selectedOrganization.org_features;
			const interviewTypes = { disabled: true, options: [], softwarePackage };

			const options = [ // disable all by default
				{
					value: 'inPerson',
					label: i18next.t('program.configuration.feedback.inPerson'),
					disabled: true,
					children: [
						{
							disabled: true,
							value: 'scale',
							label: i18next.t('program.configuration.feedback.interviewTypes.scale'),
						},
						{
							value: 'premium',
							label: i18next.t('program.configuration.feedback.interviewTypes.premium'),
							disabled: true,
						},
					],
				},
				{
					value: 'premium_async',
					label: i18next.t('program.configuration.feedback.interviewTypes.premium_async'),
					disabled: true,
				},
				{
					value: 'none',
					label: i18next.t('global.none'),
					disabled: true,
				},
			];
			if (self.isConsultant) {
				// enable all options
				options[0].disabled = false; // in person
				options[0].children[0].disabled = false; // scale
				options[0].children[1].disabled = false; // premium
				options[1].disabled = false; // async
				options[2].disabled = false; // none

				interviewTypes.disabled = false;
			} else if (interviewPackage === 'multiple') {
				const selectedInterviewPackages = orgFeatures
					?.filter(name => ['premium_interviews', 'scale_interviews', 'premium_async_interviews']
						.includes(name));
				const packageNameValues = {
					premium_interviews: 'premium',
					scale_interviews: 'scale',
					premium_async_interviews: 'premium_async',
				};
				// return options based on what interview packages they have
				selectedInterviewPackages?.forEach(name => {
					const packageName = packageNameValues[name];
					if (packageName === 'premium_async') {
						options[1].disabled = false;
					} else if (packageName === 'scale') {
						options[0].disabled = false; // enable in person
						options[0].children[0].disabled = false; // enable scale
					} else if (packageName === 'premium') {
						options[0].disabled = false; // enable in person
						options[0].children[1].disabled = false; // enable premium
					}
				});
				options.pop(); // remove 'none' option
				interviewTypes.disabled = false;
			} else if (['scale', 'premium', 'premium_async'].includes(interviewPackage)) {
				if (interviewPackage === 'premium_async') {
					options[1].disabled = false; // disable in person selections
				} else if (interviewPackage === 'scale') {
					options[0].disabled = false; // enable in person
					options[0].children[0].disabled = false; // enable scale
				} else if (interviewPackage === 'premium') {
					options[0].disabled = false; // enable in person
					options[0].children[1].disabled = false; // enable premium
				}
				interviewTypes.disabled = false; // disable ability to select any other package
				options.pop(); // remove 'none' option
			}

			// All inputs are now using the cascader, which requires an array of objects
			interviewTypes.options = options;
			return interviewTypes;
		},
		get isLegacy() {
			return self.organizationStore.selectedOrganization.software_package === 'legacy';
		},
		get hasExpiredPassword() {
			if (!self.hasFeatureFlag('password-expiration')) {
				return false;
			}

			/*
				Can't have expired password if they
					- are a consultant
					- have SSO
					- don't have a password expiration set
			*/
			const orgPasswordWindow = self?.organizationStore.selectedOrganization?.password_expiration_window;
			if (
				self.isConsultant
				|| self.organizationStore.hasSSO
				|| orgPasswordWindow === null
			) {
				return false;
			}

			// if the password is expired, return true
			const passwordLastUpdated = self.user.password_updated_at;
			if (
				!passwordLastUpdated
				|| isBefore(addDays(new Date(self.user.password_updated_at), orgPasswordWindow), new Date())
			) {
				return true;
			}

			return false;
		},
	}))
	.volatile(() => ({
		sessionChecker: null,
		resetIdleTimeoutDebounce: null,
	}))
	.actions((self) => ({
		afterCreate() {
			const ONE_SECOND = 1000;
			const ONE_MINUTE = ONE_SECOND * 60;
			const maxIntervalMinutes = 20; // less than the 30-minute timeout, but large enough that it won't put pressure on the server
			self.resetIdleTimeoutDebounce = _debounce(
				self.resetIdleTimeout,
				ONE_MINUTE,
				{
					leading: true, // send one request immediately, this will catch any last-minute actions before being logged out
					maxWait: ONE_MINUTE * maxIntervalMinutes,
					trailing: true, // send one request at end of the debounce
				},
			);
		},
		beforeDestroy() {
			self.clearSessionChecker();
		},
		clearSessionChecker() {
			clearInterval(self.sessionChecker);
			self.removeActivityListeners();
		},
		resetError() {
			self.errorCode = '';
		},
		setErrorCode(error) {
			self.errorCode = _get(error, 'response.data.errorCode', 'UNKNOWN001');
		},
		setIsSSO(isSSO) {
			self.isSSO = isSSO;
		},
		setUser(user) {
			self.user = user;
		},
		setCsrfToken(token) {
			self.csrf_token = token;
		},
		setSessionExpiration(idleExpDate, absExpDate) {
			self.idle_session_expiration = idleExpDate;
			self.abs_session_expiration = absExpDate;
		},
		checkSession() {
			const now = Date.now();

			const hasExpired = now > self.idle_session_expiration
				|| self.idle_session_expiration <= 0
				|| now > self.abs_session_expiration
				|| self.abs_session_expiration <= 0;

			if (hasExpired) {
				self.preserveOrRedirect();
			}
		},
		activateSessionCheck() {
			self.clearSessionChecker();
			self.sessionChecker = setInterval(self.checkSession, 5000); // Check every 5 seconds

			// initialize listeners to reset the idle timeout
			self.initializeActivityListeners();
		},
		initializeActivityListeners() {
			window.addEventListener('click', self.resetIdleTimeoutDebounce);
			window.addEventListener('scroll', self.resetIdleTimeoutDebounce);
			window.addEventListener('keydown', self.resetIdleTimeoutDebounce);
		},
		removeActivityListeners() {
			window.removeEventListener('click', self.resetIdleTimeoutDebounce);
			window.removeEventListener('scroll', self.resetIdleTimeoutDebounce);
			window.removeEventListener('keydown', self.resetIdleTimeoutDebounce);
		},
		resetIdleTimeout: flow(function* resetIdleTimeout() {
			if (self.user) {
				yield request.post('/session');
			}
		}),
		initializePendo() {
			// pendo is a global variable, check the index.html file where we've added the script
			const org = self.organizationStore.selectedOrganization;
			pendo.initialize({ // eslint-disable-line no-undef
				visitor: {
					id: self.user.user_id, // Required
					email: self.user.email,
					full_name: self.user.full_name,
					role: self.user?.user_roles[0]?.role?.name || null,
					department: self.user.attributes?.department || null,
					level: self.user.attributes?.level || null,
				},
				account: {
					id: org.organization_id, // Required
					name: org.name,
					type: org.type,
					software_package: org.software_package,
					interview_package: org.interview_package,
				},
			});
		},
		getSSO: flow(function* getSSO() {
			try {
				const { data } = yield request.get('/users/sso');
				self.setIsSSO(data.data.isSSO);
			} catch (error) {
				self.setErrorCode(error);
			}
		}),
		fetchSession: flow(function* fetchSession() {
			try {
				const params = {};
				const urlParams = new URLSearchParams(window.location.search);
				const auth_token = urlParams.get('auth_token');
				if (auth_token) {
					params.auth_token = auth_token;
				}

				const response = yield request.get('/session', {
					params,
				});
				const user = response.data.data;
				self.setUser(user);
				self.checkZendeskRedirect();
				if (user) {
					yield self.getSSO();
				}
				self.setCsrfToken(response.headers.csrftoken);
				self.setSessionExpiration(user.idle_session_expiration, user.abs_session_expiration);
				self.activateSessionCheck();
				self.resetError();
			} catch (error) {
				self.setErrorCode(error);
			}
		}),
		preserveOrRedirect: flow(function* preserveOrRedirect() {
			try {
				const response = yield request.get('/session');
				const user = response.data.data;
				self.setUser(user);
				self.setSessionExpiration(user.idle_session_expiration, user.abs_session_expiration);
			} catch (err) {
				// if this fails, it will get picked up by the interceptor in request.js, which will then call `logOutBySystem`
			}
		}),
		logOutBySystem(errorCode) {
			if (window.location.pathname === '/auth/login') {
				self.clearSessionChecker();
			} else if (errorCode === 'AUTH004') {
				// When the user is falsey, LoginRequiredRoute.js redirects the user to the login page.
				self.setUser(null);
				self.setCsrfToken('');
				self.setSessionExpiration();
				self.resetError();
				self.clearSessionChecker();
			}
		},
		loginUser: flow(function* loginUser(email, password) {
			try {
				const response = yield request.post('/login', { email, password });
				self.setUser(response.data.data);
				yield self.checkZendeskRedirect();
				self.addDefaultTimeZone();
				self.resetError();
				self.redirectToURLSearchQuery();
			} catch (error) {
				self.setErrorCode(error);
			}
		}),
		loginUserGoogle: flow(function* loginUserGoogle(token, subdomain) {
			try {
				const response = yield request.post('/login/google', { token, subdomain });
				self.setUser(response.data.data);
				yield self.checkZendeskRedirect();
				self.addDefaultTimeZone();
				self.resetError();
				self.redirectToURLSearchQuery();
			} catch (error) {
				self.setErrorCode(error);
			}
		}),
		logoutUser: flow(function* logoutUser() {
			yield request.post('/logout');
			yield self.preserveOrRedirect();
		}),
		addUser: flow(function* addUser(user) {
			try {
				const {
					data: { data: { organization_id, user_id, email, user_generated_accounts } },
				} = yield request.post('/user-setup/sign-up', user);
				if (user_generated_accounts) {
					window.location.assign(`/auth/email-confirmation/${organization_id}?email=${encodeURIComponent(email)}&user_id=${user_id}`);
				} else if (!user_generated_accounts) {
					window.location.assign('/auth/access-requested');
				} else {
					self.setErrorCode('UNKNOWN001');
				}
				return undefined;
			} catch (error) {
				self.setErrorCode(error);
				return error;
			}
		}),
		editUser: flow(function* editUser(body, successMessage, throwErrors = false) {
			try {
				const response = yield request.put('/users/', body);
				self.resetError();
				self.setUser(response.data.data);
				if (successMessage) {
					message.success(successMessage, 5);
				}
			} catch (error) {
				// We want to throw if we don't want 'loginRequiredRoute' to redirect us.
				if (throwErrors) {
					throw error;
				}
				self.setErrorCode(error);
			}
		}),
		setUserPassword: flow(function* setUserPassword(userId, tokenQueryString, password) {
			try {
				const { data } = yield request.post(`/emails/set_password/${userId}${tokenQueryString}`, { password });
				self.setUser(data.data);
				self.resetError();
			} catch (error) {
				self.setErrorCode(error);
				throw error;
			}
		}),
		addDefaultTimeZone() {
			if (self.user?.email && !self.user?.attributes?.time_zone) {
				const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
				if (timeZone) {
					self.changeTimeZone(timeZone);
				}
			}
		},
		changeTimeZone(time_zone) {
			self.editUser({ time_zone });
		},
		checkUserOrganizationAccess(operation, resourceType, resourceId, subResourceType, subResourceId) {
			const organizationId = self.user?.organization_id ?? self.organizationStore.organizationId;
			if (self.isConsultant) {
				return self.checkUserConsultancyAccess(operation, 'organization', organizationId);
			}

			return userHasPermission({
				scopeType: 'organization',
				scopeId: organizationId,
				resourceType,
				resourceId,
				subResourceType,
				subResourceId,
				operation,
			});
		},
		checkUserConsultancyAccess(operation, resourceType, resourceId, subResourceType, subResourceId) {
			const consultancyId = self.user.consultancy_id ?? self.organizationStore.consultancyId;
			return userHasPermission({
				scopeType: 'consultancy',
				scopeId: consultancyId,
				resourceType,
				resourceId,
				subResourceType,
				subResourceId,
				operation,
			});
		},
		removeUserHasNotifications() {
			self.user.hasNotifications = false;
		},
		postAddtionalFeedback: flow(function* postAddtionalFeedback(topic, text) {
			try {
				yield request.post('/additional-feedback', { topic, text });
				message.success(t('global.additionalFeedbackSuccess'));
			} catch (error) {
				message.error(t(`errors.${_get(error, 'response.data.errorCode', 'UNKNOWN001')}`));
				throw error;
			}
		}),
		redirectToURLSearchQuery() {
			const redirectTo = self.getParam('redirectTo');
			if (redirectTo) {
				return window.location.replace(redirectTo);
			}

			const dealParam = self.getParam('dealId');
			const responseParam = self.getParam('responseId');
			const competitorParam = self.getParam('competitorId');
			const programParam = self.getParam('programId');
			const reportParam = self.getParam('reportId');

			let redirectType = null;
			if (dealParam && responseParam) {
				redirectType = 'response';
			} else if (dealParam && !responseParam) {
				redirectType = 'deal';
			} else if (competitorParam) {
				redirectType = 'competitor';
			} else if (reportParam) {
				redirectType = 'report';
			}

			if (redirectType === null) {
				return null;
			}

			const redirectRoutes = {
				deal: {
					params: { deal_id: dealParam },
					route: `deals/${dealParam}?flagId=${self.getParam('flagId')}&action=${self.getParam('action')}`,
				},
				response: {
					params: { response_id: responseParam },
					route: `deals/${dealParam}/${responseParam}${self.responseUrlSearchParams}`,
				},
				competitor: {
					params: { competitor_id: competitorParam },
					route: `competitors/${competitorParam}`,
				},
				driver: {
					params: { competitor_id: competitorParam },
					route: `competitors/${competitorParam}`,
				},
				report: {
					params: { report_id: reportParam },
					route: `reports/${reportParam}`,
				},
			};

			const redirectOptions = redirectRoutes[redirectType];
			return window.location.replace(`/programs/${programParam}/${redirectOptions.route}`);
		},
		fetchUserSubscriptions: flow(function* fetchUserSubscriptions() {
			const { data } = yield request.get('/users/subscriptions');
			return data;
		}),
		selectSubscription: flow(function* selectSubscription({ event, type, entityId, entityType, filterIds, attributes }) {
			yield request.patch('/users/subscriptions', {
				event: event,
				type: type,
				entityId: entityId,
				entityType: entityType,
				filterIds: filterIds,
				attributes: attributes,
			});
		}),
		fetchUserTagSubscriptions: flow(function* fetchUserTagSubscriptions() {
			const data = yield self.fetchUserSubscriptions();
			const user_subscriptions_tags = data.data
				.filter((s) => s.subscription?.entity_id === self.program.programId)
				.filter((s) => s.subscription?.event === 'tag:add')
				.filter((s) => s?.type === 'email');
			self._subscribedTags = user_subscriptions_tags[0]?.attributes?.tag_ids;
		}),
		subscribeToTag: flow(function* subscribeToTag(tag_id) {
			const ids = new Set(self._subscribedTags);
			ids.add(tag_id);
			self._subscribedTags = [ ...ids.values() ];
			try {
				yield self.updateTagSubscriptions();
				message.success(i18next.t('program.tags.tagSubscribeSuccess'));
			} catch (err) {
				const errorCode = err?.response?.data?.errorCode ?? 'UNKNOWN001';
				message.error(i18next.t(`errors.${errorCode}`));
				throw err;
			}
		}),
		unsubscribeFromTag: flow(function* unsubscribeFromTag(tag_id) {
			self._subscribedTags = self._subscribedTags.filter(_id => _id !== tag_id);
			try {
				yield self.updateTagSubscriptions();
				message.success(i18next.t('program.tags.tagUnsubscribeSuccess'));
			} catch (err) {
				const errorCode = err?.response?.data?.errorCode ?? 'UNKNOWN001';
				message.error(i18next.t(`errors.${errorCode}`));
				throw err;
			}
		}),
		updateTagSubscriptions: flow(function* updateTagSubscriptions() {
			yield request.put('/users/subscriptions', {
				event: 'tag:add',
				type: 'email',
				entityId: self.program.programId,
				entityType: 'program',
				attributes: {
					tag_ids: self._subscribedTags,
				},
			});
			yield self.fetchUserTagSubscriptions();
		}),
		getZendeskJWT: flow(function* getZendeskJWT() {
			const response = yield request.get('/users/zendesk');
			return response.data.data.jwt;
		}),
		navigateToZendeskCommunity: flow(function* navigateToZendeskCommunity({
			returnTo = 'https://community.clozd.com',
			shouldOpenNewTab = false,
		}) {
			if (!self.hasFeatureFlag('zendesk-sso')) {
				return;
			}
			const zendeskJWT = yield self.getZendeskJWT();

			// Create a form element
			const form = document.createElement('form');
			form.method = 'POST';

			// Append `return_to` as a query parameter if provided
			const baseUrl = 'https://clozdhelp.zendesk.com/access/jwt';
			form.action = returnTo ? `${baseUrl}?return_to=${encodeURIComponent(returnTo)}` : baseUrl;

			// If `shouldOpenNewTab`, open in a new tab
			if (shouldOpenNewTab) {
				form.target = '_blank'; // This makes the form submission open in a new tab
			}

			// Create an input element for JWT
			const jwtInput = document.createElement('input');
			jwtInput.type = 'hidden';
			jwtInput.name = 'jwt';
			jwtInput.value = zendeskJWT;

			// Append the input to the form
			form.appendChild(jwtInput);

			// Append the form to the body (necessary for submission)
			document.body.appendChild(form);

			// Submit the form
			yield new Promise((resolve, reject) => {
				try {
					form.submit();  // Submit the form
					resolve();      // Resolve the promise after submission
				} catch (error) {
					reject(error);  // Reject if there's an error during submission
				}
			});

			// Remove the form from the DOM after submission
			form.parentNode.removeChild(form);
		}),
		checkZendeskRedirect: flow(function* checkZendeskRedirect() {
			const zendeskReturnTo = self.getParam('return_to');
			if (zendeskReturnTo) {
				yield self.navigateToZendeskCommunity({ returnTo: zendeskReturnTo, shouldOpenNewTab: false });
			}
		}),
	}));
