import { types, flow, getEnv } from 'mobx-state-tree';
import request from '@app/js/api/request';
import DriverRatingModel from '@baseModels/driverRating.baseModel';
import _get from 'lodash/get';
import { message } from 'antd';
import i18next from 'i18next';

const { string, maybe, maybeNull, model, optional, boolean, number, map, array } = types;

export const QuotesDrawerStoreModel = model('QuotesDrawerStore', {
	competitorId: maybeNull(string),
	driver_id: maybeNull(string),
	showDrawer: optional(boolean, false),
	isLoadingQuotes: optional(boolean, false),
	isLoadingDriverRatings: optional(boolean, false),
	quotes: optional(model('quote', {
		count: optional(number, 0),
		rows: types.array(model('quote_rows', {
			amount: maybeNull(string),
			deal_id: optional(string, ''),
			driver_id: optional(string, ''),
			organization_entity_id: optional(string, ''),
			organization_entity_name: optional(string, ''),
			organization_entity_domain: maybeNull(string),
			outcome: optional(string, ''),
			outcome_type: maybeNull(string),
			publish_date: optional(string, ''),
			quote: optional(string, ''),
			rating: maybe(number),
			response_quote_id: optional(string, ''),
			response_id: optional(string, ''),
			channel: maybeNull(string),
		})),
	}), {}),
	_driverRatings: map(DriverRatingModel),
	currentOutcome: maybeNull(string),
	dividedOutcomes: array(string),
	currentSentiment: optional(string, ''),
	ratingsDivision: maybeNull(string),
})
	.views((self) => ({
		get programStore() {
			return getEnv(self).ProgramStore;
		},
		get driver() {
			return self.programStore.getDriver(self.driver_id);
		},
		get driverRatingAll() {
			if (self.competitorId) {
				return Object.fromEntries(self._driverRatings)[self.driver_id] || {};
			}
			return self.programStore.getDriverRating(self.driver_id);
		},
		get driverRating() {
			let rating;
			if (self.competitorId) {
				rating = Object.fromEntries(self._driverRatings)[self.driver_id] || {};
			}
			rating = self.programStore.getDriverRating(self.driver_id);
			if (self.ratingsDivision) {
				return rating[self.ratingsDivision];
			}
			return rating;
		},
		get isLoading() {
			return self.isLoadingQuotes || self.isLoadingDriverRatings;
		},
		get isWinOrLoss() {
			return ['win', 'loss'].includes(self.currentOutcome);
		},
		get driverSummaryKey() {
			if (self.dividedOutcomes.length > 0) {
				return 'program.global.driverQuotesSummaryDivided';
			}
			return 'program.global.driverQuotesSummary';
		},
		get showFilterExplanation() {
			return (self.dividedOutcomes.length === 0) && self.isWinOrLoss;
		},
		get filterExplanationKey() {
			if (self.currentOutcome === 'win') {
				if (self.currentSentiment === 'positive') {
					return 'program.global.positiveModeExplanation';
				}
				return 'program.global.winModeExplanation';
			}
			if (self.currentOutcome === 'loss') {
				if (self.currentSentiment === 'negative') {
					return 'program.global.negativeModeExplanation';
				}
				return 'program.global.lossModeExplanation';
			}
			return null;
		},
		get lastQuotesKey() {
			if (self.dividedOutcomes.length > 0) {
				return self.currentSentiment ? 'program.global.lastQuotesDividedWithSentiment' : 'program.global.lastQuotesDivided';
			}
			return 'program.global.lastQuotes';
		},
		get lastQuotesValues() {
			return {
				count: self.quotes.rows.length,
				sentiment: self.currentSentiment,
			};
		},
	}))
	.actions((self) => ({
		updateDriverID(driver_id) {
			self.driver_id = driver_id;
		},
		updateIsLoadingQuotes(isLoadingQuotes) {
			self.isLoadingQuotes = isLoadingQuotes;
		},
		updateQuotes(quotes) {
			self.quotes = quotes;
		},
		updateShowDrawer(showDrawer) {
			self.showDrawer = showDrawer;
		},
		triggerDrawer(driver_id, outcome, ratings, competitorId = null, dividedOutcomes = [], ratingsDivision = null) {
			self.competitorId = competitorId; // can't easily get the competitorId off of the params so storing it on the model when it's passed as an argument
			if (competitorId) {
				self.fetchDriverRatings();
			}
			self.updateDriverID(driver_id);
			self.fetchQuotes(driver_id, outcome, ratings, competitorId, dividedOutcomes);
			self.currentOutcome = outcome;
			self.dividedOutcomes = dividedOutcomes;
			self.ratingsDivision = ratingsDivision;
			if (ratings instanceof Array) {
				if (ratings.every(r => r > 0)) {
					self.currentSentiment = 'positive';
				} else if (ratings.every(r => r < 0)) {
					self.currentSentiment = 'negative';
				} else {
					self.currentSentiment = '';
				}
			} else {
				self.currentSentiment = '';
			}
			self.updateShowDrawer(true);
		},
		fetchQuotes: flow(function* fetchQuotes(driver_id, outcome, ratings, competitorId, dividedOutcomes) {
			try {
				self.updateIsLoadingQuotes(true);
				self.updateQuotes();
				let driver_ids = [driver_id];
				if (self.driver.children) {
					driver_ids = [].concat(driver_ids, ...self.driver.children);
				}
				const quotesResponse = yield request.get(`${self.programStore.baseURL}/drivers/quotes`, {
					params: {
						page: 1,
						pageSize: 10,
						outcome: outcome,
						ratings: ratings,
						filters: self.programStore.filtersString,
						driver_ids: driver_ids,
						competitorId: competitorId,
						dividedOutcomes,
					},
				});
				self.updateQuotes(quotesResponse.data.data.values);
				self.updateIsLoadingQuotes(false);
			} catch (err) {
				message.error(i18next.t(`errors.${_get(err, 'response.data.errorCode', 'UNKNOWN001')}`));
				throw err;
			}
		}),
		fetchDriverRatings: flow(function* fetchDriverRatings() {
			try {
				self.isLoadingDriverRatings = true;
				const { data } = yield self.programStore.fetchDriverRatings({
					filters: self.programStore.filtersString,
					competitorId: self.competitorId,
				});
				self._driverRatings = data.data.values;
			} catch (err) {
				message.error(i18next.t(`errors.${_get(err, 'response.data.errorCode', 'UNKNOWN001')}`));
				throw err;
			} finally {
				self.isLoadingDriverRatings = false;
			}
		}),
	}));
